.formWrapper {


  form {
    margin: 50px 0px 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    //justify-content: center;
    height: 100%;

    button {
      margin-top: 50px;
      margin-bottom: 50px;
    }


  }
  @media only screen and (max-width: 1023px) {

    form {
      margin-bottom: 0px;
    }
  }
}


@media only screen and (min-width: 960px) {

  .formWrapper {
    height: calc(100vh - 150px);
  }
}






