.footerWrapper {
  height: 80px;
  background-color: #4351AF;
  display: flex;
  justify-content: center;
  align-items: center;
  //position: absolute;
  //bottom: 0;
  //width: 100%;

  h4 {
    color: white;
  }
}

@media only screen and (max-width: 1023px) {
  .footerWrapper {
    transform: translateY(10px);
  }
}
@media only screen and (min-width: 1023px)  {
  .footerWrapper {
    transform: translateY(-80px);
  }
}

@media only screen and (orientation: landscape) and (min-width: 1023px)  {
  .footerWrapper {
    transform: translateY(-80px);
  }
}
@media only screen and (orientation: landscape) and (max-width: 1024px)  {
  .footerWrapper {
    transform: translateY(0px);
  }
}


