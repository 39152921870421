.headerWrapper {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4351AF;
  h2 {
    color: white;
  }
}
