h2 , h4 {
  text-align: center;
}
body {
  padding: 0;
  margin: 0;
}

.MuiOutlinedInput-multiline{
  padding-right: 0 !important;
}

.App {
  position: relative;
  height: calc(100vh - 100px);}

@media only screen and (max-width: 1023px) {
  .App {
    position: relative;
    height: 100%;
  }

}

.content {
  height: calc(100vh - 80px);
}
@media only screen and (max-width: 1023px)  {
  .content {
    height: 100%;
  }
}

@media only screen and (orientation: landscape)  {
  .content {
    height: 100%;
  }
}
